import Image from 'next/legacy/image'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'

import { breakpoints, colors } from '@/styles'

import verified from './images/verified@3x.png'

const Wrapper = styled.section`
  display: flex;
  justify-content: space-between;
  background-color: ${colors.white};
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.07);
  padding: 80px 60px;

  @media (max-width: ${breakpoints.tabletLandscape}) {
    flex-direction: column;
    justify-content: flex-start;
  }

  @media (max-width: ${breakpoints.ipadMiniMax}) {
    padding: 60px 25px;
  }
`

const Content = styled.div`
  flex: 0 0 50%;
  max-width: 375px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (max-width: ${breakpoints.tabletLandscape}) {
    max-width: unset;
    flex: 1;
  }
`

const Title = styled.h3`
  color: ${colors.regular};
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: -0.5px;
  margin: 0 0 100px;

  @media (max-width: ${breakpoints.card}) {
    margin-bottom: 80px;
  }

  @media (max-width: ${breakpoints.desktop}) {
    margin-bottom: 80px;
  }

  @media (max-width: ${breakpoints.tabletLandscape}) {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
`

const Description = styled.p`
  font-family: futura-pt-bold, Futura, sans-serif;
  color: ${colors.regular};
  font-size: 45.5px;
  font-weight: 700;
  line-height: 46px;
  letter-spacing: -0.14px;
  margin: 0;

  @media (max-width: ${breakpoints.tabletLandscape}) {
    font-size: 27.5px;
    line-height: 32px;
    letter-spacing: -0.03px;
  }
`

const ImageWrapper = styled.div`
  flex: 0 0 50%;
  @media (max-width: ${breakpoints.tabletLandscape}) {
    width: 100%;
    justify-content: center;
    margin-top: 30px;
    flex-grow: 1;
    align-self: center;
    width: 348px;
  }
`

const ApplyTo = () => {
  const { t } = useTranslation('home')
  return (
    <Wrapper>
      <Content>
        <Title>{t('home:Welcome to your safe space.')}</Title>
        <Description>
          {t(
            'home:Our layered verification processes help protect renters like you, so you can search, inquire & apply to listings with ease.'
          )}
        </Description>
      </Content>
      <ImageWrapper>
        <Image
          layout="responsive"
          placeholder="blur"
          src={verified}
          alt=""
          sizes={`(max-width: ${breakpoints.tabletLandscape}) 148px, (max-width: ${breakpoints.desktop}) 434px, (max-width: ${breakpoints.card}) 474px, 523px`}
        />
      </ImageWrapper>
    </Wrapper>
  )
}

export default ApplyTo
