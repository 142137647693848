import styled from 'styled-components'

import { toStyle } from './common'

export const Grid = styled.div`
  display: grid;
  grid-row-gap: 30px;
  grid-column-gap: 30px;
  justify-content: center;

  ${props => toStyle(props)};
`

export default Grid
