import Image from 'next/legacy/image'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import styled from 'styled-components'

import { breakpoints, colors, fontSizes, fontWeights } from '@/styles'
import useMediaQuery from '@/hooks/use-media-query'
import { Card } from '@/features/Renters/elements'
import { Button } from '@/components/button'
import { Grid } from '@/components/styled-system'

import { SubHeader, SubHeaderTitle } from '../elements'
import ApplyTo from './apply-to'
import Icon from './icon'
import rentermobileImage from './images/renter-mobile.webp'
import renterImage from './images/renter.webp'
import badgeIcon from './images/badge.png'
import chatIcon from './images/chat.png'
import contractIcon from './images/contract.png'
import moneyIcon from './images/money.png'
import showingIcon from './images/showing.png'
import uploadIcon from './images/upload.png'

const AutomateContent = styled.div`
  width: 100%;
  display: grid;
  padding: 0 60px;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0;
  grid-row-gap: 75px;
  @media (max-width: ${breakpoints.card}) {
    padding: 92px 60px;
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: ${breakpoints.phoneMax}) {
    padding: 25px 28px 36px;
    grid-row-gap: 45px;
    grid-column-gap: 5px;
  }
  ${props =>
    props.isFrench &&
    `
    padding: 0 50px;
    @media (max-width: 911px) {
      padding: 92px 5%;
    }
    @media (max-width: 864px) {
      padding: 50px 1%;
      font-size: 16px;
    }
    @media (max-width: 435px) {
      grid-template-columns: 90px 90px;
      grid-column-gap: 50px;
      justify-content: center;
    }
    `}
`

const DescWrapper = styled.div`
  ${props =>
    props.isFrench &&
    props.itemId === 4 &&
    `
    @media (max-width: 435px) {
      width: 70px;
    }
    `}
`

const AutomatedFeatures = styled.div`
  height: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div {
    flex: 0 0 50%;
  }
  @media (max-width: ${breakpoints.card}) {
    flex-direction: column;
    justify-content: flex-start;
    > div {
      flex: initial;
    }
    > div:last-of-type {
      flex: 1;
    }
  }
`

const ImageWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`

const AutomateItem = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.white};
  font-size: 18px;
  font-weight: ${fontWeights.medium};
  letter-spacing: -0.22px;
  line-height: 32px;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;

  @media (max-width: ${breakpoints.phoneMax}) {
    font-size: ${fontSizes.small};
    letter-spacing: -0.16px;
    line-height: 25px;
  }
`

const Wrapper = styled.div`
  background: ${colors.background};
  padding: 0 0 170px;
  @media (max-width: ${breakpoints.phoneMax}) {
    padding-bottom: 100px;
  }
`

const TextWrapper = styled.h2`
  padding: 0 30px;
  margin: 0 auto;
  max-width: 1210px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  @media (max-width: ${breakpoints.phoneMax}) {
    align-items: start;
    padding: 0 25px;
    ${SubHeader} {
      max-width: 395px;
    }
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;
  @media (max-width: ${breakpoints.phoneMax}) {
    margin-top: 50px;
  }
`

const ButtonText = styled.span`
  text-align: center;
  min-width: 160px;
`

const ForRenters = () => {
  const { t, i18n } = useTranslation('home')
  const isFrench = i18n.languages[0] === 'fr'
  const displaySingleColumn = useMediaQuery(`(max-width: ${breakpoints.card})`)
  const displayMobile = useMediaQuery(`(max-width: ${breakpoints.phoneMax})`)

  const automate = [
    {
      id: 1,
      name: t('home:Verified listings'),
      icon: badgeIcon
    },
    {
      id: 2,
      name: t('home:Inquire instantly'),
      icon: chatIcon
    },
    {
      id: 3,
      name: t('home:Sign digital contracts'),
      icon: contractIcon
    },
    {
      id: 4,
      name: t('home:Apply online'),
      icon: uploadIcon
    },
    {
      id: 5,
      name: t('home:Book showings'),
      icon: showingIcon
    },
    {
      id: 6,
      name: t('home:Pay rent online'),
      icon: moneyIcon
    }
  ]

  return (
    <Wrapper>
      <TextWrapper>
        <SubHeaderTitle
          textAlign={['left']}
          padding={['180px 0 15px', '105px 0 15px']}
          fontSize={['24px', '18px']}
          as="span"
        >
          {t('home:For Renters')}
        </SubHeaderTitle>
        <SubHeader
          textAlign={['left']}
          padding={['0 0 85px', '0 0 50px']}
          as="span"
        >
          {t('home:No more scams.')}
        </SubHeader>
      </TextWrapper>
      <Grid
        gridTemplateColumns={['minmax(950px, 1150px)', '0.9fr', '0.85fr']}
        gridAutoRows={['650px 760px', '940px 700px', '700px 750px']}
      >
        <Card background="#f29c95" noPadding>
          <AutomatedFeatures>
            <ImageWrapper>
              <Image
                src={displayMobile ? rentermobileImage : renterImage}
                alt=""
                sizes={displaySingleColumn ? '100vw' : '575px'}
                layout="fill"
                objectFit="cover"
                placeholder="blur"
              />
            </ImageWrapper>
            <AutomateContent isFrench={isFrench}>
              {automate.map(item => (
                <AutomateItem key={item.id}>
                  <Icon src={item.icon} />
                  <DescWrapper isFrench={isFrench} itemId={item.id}>
                    {item.name}
                  </DescWrapper>
                </AutomateItem>
              ))}
            </AutomateContent>
          </AutomatedFeatures>
        </Card>
        <ApplyTo />
      </Grid>
      <ButtonWrapper>
        <Link href="/renters" passHref legacyBehavior>
          <Button
            as="a"
            id="home-renters-findyourhome-click"
            theme="secondary"
            radius={50}
            shadow
          >
            <ButtonText>{t('home:Find your home now')}</ButtonText>
          </Button>
        </Link>
      </ButtonWrapper>
    </Wrapper>
  )
}

export default ForRenters
