const toImageSet = ({ images }, isMobile) => {
  const inner = Object.entries(images)
    .filter(([key]) => {
      const hasMobile = key.startsWith('mobile')
      return isMobile ? hasMobile : !hasMobile
    })
    .map(([key, value]) => {
      const size = key.replace(isMobile ? 'mobile@' : '@', '')
      return `url(${value}) ${size}`
    })
    .join(', ')
  return `image-set(${inner})`
}

export default toImageSet
