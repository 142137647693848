import { omit } from 'ramda'
import kebabCase from 'lodash/kebabCase'

import { breakpoints as bps } from '@/styles'

const defaultBreakpoints = ['1200px', bps.phoneMax]

const isShadow = (arr, i) => i !== 0 && arr[i - 1] === arr[i]

const limit = (arr, n) => {
  const length = [...Array(n)]
  return Array.isArray(arr)
    ? length.map((el, i) => arr[Math.min(i, arr.length - 1)])
    : length.map(() => arr)
}

const collect = props => {
  const { breakpoints: custom, ...rest } = props
  const breakpoints = ['', ...(custom || defaultBreakpoints)]
  const n = breakpoints.length
  const styles = Object.entries(rest).reduce(
    (acc, [key, arr]) => {
      const limited = limit(arr, n)
      return acc.map((el, i) => {
        if (!isShadow(limited, i)) el[kebabCase(key)] = limited[i]
        return el
      })
    },
    [...Array(n)].map(() => ({}))
  )
  return breakpoints.map((key, i) => [key, styles[i]])
}

export const toStyle = props => {
  const rest = omit(['children', 'theme'], props)
  return collect(rest)
    .map(([breakpoint, styles]) => {
      const inner = Object.entries(styles)
        .map(([prop, value]) => `${prop}: ${value};`)
        .join('\n')
      if (!inner || !breakpoint) return inner
      return `
   @media (max-width: ${breakpoint}) {
     ${inner}
   }
  `
    })
    .join('')
}
