import { ReactNode } from 'react'
import styled, { css, FlattenSimpleInterpolation } from 'styled-components'

import { breakpoints, colors } from '@/styles'
import toImageSet from '@/utils/to-image-set'

export interface Image {
  ext: string
  images: Record<string, string>
}

interface CardProps {
  background?: string
  long?: boolean
  noPadding?: boolean
  padding?: string
  image?: Image
  breakpoint?: string
  children?: ReactNode
}

export const Grid = styled.div<{ breakpoint?: string }>`
  display: grid;
  grid-row-gap: 30px;
  grid-column-gap: 30px;
  grid-auto-rows: 818px;
  width: 100%;
  justify-content: center;
  grid-template-columns: repeat(2, minmax(460px, 560px));

  @media (max-width: ${props => props.breakpoint}) {
    grid-template-columns: 0.9fr;
    > div,
    > a {
      grid-column: 1 span;
    }
  }
  @media (max-width: ${breakpoints.tabletLandscape}) {
    grid-auto-rows: 710px;
  }
  @media (max-width: ${breakpoints.phoneMax}) {
    grid-template-columns: 0.85fr;
    grid-auto-rows: 150px;
    > div,
    > a {
      grid-row: 3 span;
    }
  }
`

export const Card = styled.div<CardProps>`
  background-color: ${props => props.background || colors.white};
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.07);
  padding: 80px 60px;

  @media (max-width: ${breakpoints.phoneMax}) {
    padding: 55px 25px;
    ${props =>
      props.long &&
      `
      grid-row: 4 span !important;
    `}
  }

  ${props =>
    props.noPadding &&
    `
    padding: 0 !important;
  `}
`

export const Text = styled.h3<{ customStyle?: FlattenSimpleInterpolation }>`
  color: ${props => props.color || colors.regular};
  font-weight: 700;
  letter-spacing: -0.35px;
  line-height: 29px;
  margin: 0 0 10px;
  font-size: 24px;
  @media (max-width: ${breakpoints.phoneMax}) {
    font-size: 18px;
    line-height: 24px;
  }

  ${({ customStyle }) => customStyle}
`

const normalText = css<{ color?: string }>`
  color: ${props => props.color || colors.darkGrey};
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  letter-spacing: -0.2px;
  max-width: 500px;
  font-family: 'Open Sans', sans-serif;
  margin: 0;
`

const largeText = css<{ color?: string }>`
  color: ${props => props.color || colors.regular};
  font-family: futura-pt-bold, Futura, sans-serif;
  font-size: 45.5px;
  font-weight: 700;
  line-height: 46px;
  letter-spacing: -0.14px;
  margin: auto 0;
`

export const Description = styled.div<{
  id?: string
  large?: boolean
  alwaysLarge?: boolean
  breakpoint?: string
  maxWidth?: string
  customStyle?: FlattenSimpleInterpolation
}>`
  ${props => (props.large || props.alwaysLarge ? largeText : normalText)};

  ${props =>
    !props.alwaysLarge &&
    `
    @media (max-width: ${props.breakpoint}) {
      ${
        props.large &&
        `
        ${normalText};
        color: ${colors.darkGrey};

      `
      };
      max-width: ${props.maxWidth || '275px'};
    }
  `};

  @media (max-width: ${breakpoints.phoneMax}) {
    max-width: 285px;
    font-size: ${props => (props.alwaysLarge ? 16 : 14)}px;
    line-height: 21px;
  }

  ${({ customStyle }) => customStyle}
`

const Wrapper = styled(Card)`
  ${props =>
    props.image &&
    `
    background-image: ${toImageSet(props.image)};
  `}
  background-repeat: no-repeat;
  background-position: bottom center;
  @media (max-width: ${props => props.breakpoint}) {
    ${props =>
      props.image &&
      `
      background-image: url(${props.image.images['@2x']});
    `}
    background-position-x: calc(100% + 130px);
    background-position-y: calc(100% + 374px);
    background-size: auto 130%;
  }
  @media (max-width: ${breakpoints.tabletLandscape}) {
    background-position-x: right;
    background-position-y: calc(100% + 40px);
    background-size: auto 80%;
  }
  @media (max-width: ${breakpoints.ipadMiniMax}) {
    background-size: auto 70%;
  }
  @media (max-width: ${breakpoints.phoneMax}) {
    background-position-x: center;
    background-position-y: calc(100% + 45px);
    background-size: auto 77%;
  }
`

export const ImageCard = (props: CardProps) => <Wrapper {...props} long />

const Container = styled.div<{ breakpoint?: string; padding?: string }>`
  height: 100%;
  display: flex;
  width: calc(50% - ${props => props.padding || 0});
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: ${props => props.breakpoint}) {
    width: 100%;
    justify-content: flex-start;
  }
`

const LargeWrapper = styled(Card)`
  ${props =>
    props.long &&
    `
    grid-column: 1 / 2 span;
  `}
  ${props =>
    props.image &&
    `
    background-image: ${toImageSet(props.image)};
  `}
  background-repeat: no-repeat;
  background-position: bottom right;
  @media (max-width: ${breakpoints.tabletLandscape}) {
    ${props =>
      props.image &&
      `
    background-image: ${toImageSet(props.image, true)};
    `}
    background-position-x: right;
    background-position-y: calc(100% + 40px);
    background-size: auto 80%;
  }
  @media (max-width: ${breakpoints.ipadMiniMax}) {
    background-size: auto 70%;
  }
  @media (max-width: ${breakpoints.phoneMax}) {
    background-position-x: center;
    background-position-y: calc(100% + 45px);
    background-size: auto 77%;
  }
`

export const LargeImageCard = ({
  long,
  breakpoint,
  padding,
  image,
  children
}: CardProps) => (
  <LargeWrapper long={long} breakpoint={breakpoint} image={image}>
    <Container breakpoint={breakpoint} padding={padding}>
      {children}
    </Container>
  </LargeWrapper>
)

export const ColorCard = styled(Card)`
  background-color: ${props => props.color || colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
