import Image from 'next/legacy/image'
import styled from 'styled-components'

import { breakpoints } from '@/styles'

const ImageWrapper = styled.div`
  height: 48px;
  width: 48px;
  margin-bottom: 18px;

  @media (max-width: ${breakpoints.phoneMax}) {
    height: 35px;
    width: 35px;
    margin-bottom: 5px;
  }

  > span > img {
    filter: brightness(100);
  }
`

const Icon = ({ src }) => (
  <ImageWrapper>
    <Image src={src} alt="" width={100} height={100} />
  </ImageWrapper>
)

export default Icon
